import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/[locale]/(landingPage)/main.tsx");
;
import(/* webpackMode: "eager" */ "/app/asset/icon/logo/playsee_logo_new.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/component/block/header/home-header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/component/block/language-selector/language-selector.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Separator"] */ "/app/component/ui/separator.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
